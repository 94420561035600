import React, { Component } from "react";
import HeaderNavBar from "../../HeaderNavBar/HeaderNavBar.js";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import axios from "axios";
import "../PaymentSiteOwner/Payment.css";
import Sidebar from "../SidebarAnnualReportSiteOwner/Sidebar.js";
import TranslationFile from "../../Translation/Translation.json";

let userSelectedLanguage;
let loginDetails = "";
class Payment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      packagesDialogVisible: false,
      userPackages: "",
      userPackagesLine: "",
      globalFilter: null,
      loading: false,
      sideBarStatus: true,
      showPaymentPage: false,
    };
    this.getPaymentLine = this.getPaymentLine.bind(this);
    this.packagesBodyTemplate = this.packagesBodyTemplate.bind(this);
  }

  getPaymentLine(paymentEntryNo) {
    axios
      .get(
        "/siteOwner/getAnnualReportPackageUsageByPaymentEntryNoForSO?paymentEntryNo=" +
          paymentEntryNo
      )
      .then((response) => {
        let respData = response.data;
        respData.map((res) => {
          if (res.status) {
            res.status = "Active";
          } else {
            res.status = "InActive";
          }
          return res;
        });
        if (respData.length > 0) {
        
          this.setState({
            userPackagesLine: respData,
            packagesDialogVisible: true,
          });
        }
      });
  }

  packagesBodyTemplate(rowData) {
    if (
      rowData.paymentStatus == "readyToShip" ||
      rowData.paymentStatus == "shipped"
    ) {
      return (
        <div
          style={{
            textDecoration: "underline",
            color: "blue",
            cursor: "pointer",
          }}
          onClick={() => this.getPaymentLine(rowData.paymentEntryNo)}
          // onClick={() => this.setState({ packagesDialogVisible: true })}
        >
          {TranslationFile.PurchasedPackages[userSelectedLanguage]}
        </div>
      );
    } else {
      return (
        <div>
          <p> {TranslationFile.PurchasedPackages[userSelectedLanguage]}</p>
        </div>
      );
    }
  }

  componentWillMount() {
    userSelectedLanguage = localStorage.getItem("selectedLanguage");
    loginDetails = localStorage.getItem("RBLSiteOwner");
    loginDetails = JSON.parse(loginDetails);
    if (loginDetails != null && loginDetails["email"]) {
      this.setState({ showPaymentPage: true });

      axios
        .get("/siteOwner/getAnnualReportPackagePaymentForSO")
        .then((response) => {
          let respData = response.data;
          respData.map((res) => {
            if (res.paymentMethod == 0) return (res.paymentMethod = "Payson");
          });
          respData = respData.map(item =>
            Object.fromEntries(
              Object.entries(item).map(([key, value]) => [key, value === '' ? null : value])
            )
          );
          this.setState({ userPackages: respData });
        });
    } else {
      this.setState({ showPaymentPage: false });
      this.props.history.push("/siteOwner");
    }
  }

  sideBarStatus = (e) => {
    this.setState({
      sideBarStatus: e,
    });
  };

  paymentMethodBodyTemplate(rowdata) {}

  render() {
    const { sideBarStatus } = this.state;
    const header = (
      <div className="Payment-Header-Tab">
        {TranslationFile.PaymentInformation[userSelectedLanguage]}
        {/* Payment Information */}
        <span className="p-input-icon-left">
          <InputText
            type="search"
            onInput={(e) => this.setState({ globalFilter: e.target.value })}
            // placeholder="Global Search"
            placeholder={TranslationFile.GlobalSearch[userSelectedLanguage]}
          />
        </span>
      </div>
    );
    return (
      <div className="Payment-Main-Div">
        {this.state.showPaymentPage && (
          <div>
            <div>
              <HeaderNavBar />
            </div>
            <div className="Payment_Sidebar">
              <Sidebar
                children="Payment"
                sideBarStatus={this.sideBarStatus.bind(this)}
              />
            </div>
            <div
              className={
                sideBarStatus ? "Payment-DataTable" : "payment-DataTableClose"
              }
            >
              <div className="Payment-Header-Title"> {header} </div>
              <DataTable
                value={this.state.userPackages}
                globalFilter={this.state.globalFilter}
                loading={this.state.loading}
                emptyMessage={
                  TranslationFile.NoRecordsfound[userSelectedLanguage]
                }
                paginator
                rows={10}
                rowsPerPageOptions={[5, 10, 25, 50]}
              >
                <Column
                  field="invoiceNo"
                  header={TranslationFile.InvoiceNo[userSelectedLanguage]}
                  filter
                ></Column>
                <Column
                  field="paymentEntryNo"
                  header={TranslationFile.PaymentEntryNo[userSelectedLanguage]}
                  filter
                ></Column>
                <Column
                  field="userId"
                  header={TranslationFile.UserID[userSelectedLanguage]}
                  filter
                ></Column>
                <Column
                  field="name"
                  header={TranslationFile.UserName[userSelectedLanguage]}
                  filter
                ></Column>
                <Column
                  field="emailId"
                  header={TranslationFile.UserEmail[userSelectedLanguage]}
                  filter
                ></Column>
                <Column
                  field="paymentMethod"
                  header={TranslationFile.PaymentMethod[userSelectedLanguage]}
                  body={this.paymentMethodBodyTemplate()}
                  filter
                ></Column>
                <Column
                  field="paymentStatus"
                  header={TranslationFile.PaymentStatus[userSelectedLanguage]}
                  filter
                ></Column>
                <Column
                  field="totalPrice"
                  header={TranslationFile.TotalPrice[userSelectedLanguage]}
                  filter
                ></Column>
                <Column
                  header={TranslationFile.packagesList[userSelectedLanguage]}
                  body={this.packagesBodyTemplate}
                  filter
                ></Column>
              </DataTable>
            </div>
            <Dialog
              visible={this.state.packagesDialogVisible}
              onHide={() => this.setState({ packagesDialogVisible: false })}
              header={TranslationFile.UserPackagesList[userSelectedLanguage]}
            >
              <DataTable
                value={this.state.userPackagesLine}
                style={{ width: "40vw" }}
              >
                <Column
                  field="entryNo"
                  header={TranslationFile.EntryNo[userSelectedLanguage]}
                ></Column>
                <Column
                  field="packageName"
                  header={TranslationFile.PackageName[userSelectedLanguage]}
                ></Column>
                <Column
                  field="quantity"
                  header={TranslationFile.Quantity[userSelectedLanguage]}
                ></Column>
                <Column
                  field="maxUsage"
                  header={TranslationFile.MaxUsage[userSelectedLanguage]}
                ></Column>
                <Column
                  field="actualUsage"
                  header={TranslationFile.ActualUsage[userSelectedLanguage]}
                ></Column>
                <Column
                  field="status"
                  header={TranslationFile.Status[userSelectedLanguage]}
                ></Column>
              </DataTable>
            </Dialog>
          </div>
        )}
      </div>
    );
  }
}
export default Payment;
