import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import axios from "axios";
import { InputText } from "primereact/inputtext";
import "../PrivateUsersSiteOwner/PrivateUsers.css";
import Sidebar from "../SidebarAnnualReportSiteOwner/Sidebar.js";
import HeaderNavBar from "../../HeaderNavBar/HeaderNavBar.js";
import TranslationFile from "../../Translation/Translation.json";
import { setUserSessionAuthKey } from "../../../Component/commonUtils.js";
import { Button } from "primereact/button";

let userSessionAuthKeyReturnValue = setUserSessionAuthKey();
let loginDetails = "";
let userSelectedLanguage;
class PrivateUsers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      globalFilter: null,
      emailQueueData: "",
      sideBarStatus: true,
      sendBtnLoading: false,
      currentEntryNo: "",
      showEmailQueuePage: false,

      reportsDialogVisible: false,
      userTableValues: "",
      modifiedformatdatetime: "",
      loading: false,
      showPrivateUsers: false,
    };
    this.emailSendAction = this.emailSendAction.bind(this);
    this.sendMailBtnBodyTemplate = this.sendMailBtnBodyTemplate.bind(this);
  }

  componentWillMount() {
    userSelectedLanguage = localStorage.getItem("selectedLanguage");
    loginDetails = localStorage.getItem("RBLSiteOwner");
    loginDetails = JSON.parse(loginDetails);
    if (loginDetails != null && loginDetails["email"]) {
      this.setState({ showPrivateUsers: true });

      axios
        .get("/siteOwner/getAnnualReportUsersForSiteOwner")
        .then((response) => {
          let respData = response.data.privateUsersList;
          respData.map((res) => {
            if (res.mobileNo == "0") return (res.mobileNo = "-");
          });
          respData.map((i) => {
            let date = i.createdAtDateTime.split("T")[0];
            return (i.createdAtDateTime = date);
          });
          respData = respData.map(item => (
            Object.fromEntries(
              Object.entries(item).map(([key, value]) => [key, value === '' ? null : value])
            )
          ));

          this.setState({
            userTableValues: respData,
          });
        });

        if (userSessionAuthKeyReturnValue) {
          axios
            .get(
              "/siteOwner/getEmailQueueForSiteOwner",
              userSessionAuthKeyReturnValue
            )
            .then((response) => {
              let respData = response.data;
              this.setState({
                emailQueueData: respData,
              });
            });
        }
    } else {
      this.setState({ showPrivateUsers: false });
      this.props.history.push("/siteOwner");
    }
  }

  // componentWillMount() {
  //   userSelectedLanguage = localStorage.getItem("selectedLanguage");
  //   loginDetails = localStorage.getItem("RBLSiteOwner");
  //   loginDetails = JSON.parse(loginDetails);
  //   if (loginDetails != null && loginDetails["email"]) {
  //     this.setState({ showEmailQueuePage: true });

  //     userSessionAuthKeyReturnValue = setUserSessionAuthKey();
  //     if (userSessionAuthKeyReturnValue) {
  //       axios
  //         .get(
  //           "/siteOwner/getEmailQueueForSiteOwner",
  //           userSessionAuthKeyReturnValue
  //         )
  //         .then((response) => {
  //           let respData = response.data;
  //           this.setState({
  //             emailQueueData: respData,
  //           });
  //         });
  //     }
  //   } else {
  //     this.setState({ showEmailQueuePage: false });
  //     this.props.history.push("/siteOwner");
  //   }
  // }

  acceptToSendMail(rowData) {
    this.setState({
      sendBtnLoading: true,
      currentEntryNo: rowData.entryNo,
    });
    userSessionAuthKeyReturnValue = setUserSessionAuthKey();
    if (userSessionAuthKeyReturnValue) {
      axios
        .post(
          "/emailQueueController/sendMail?EmailQueueEntryNo=" + rowData.entryNo
        )
        .then((response) => {
          this.setState({ sendBtnLoading: false });
          this.toast.show({
            severity: "success",
            summary: "success",
            life: 3000,
          });
        })
        .catch((error) => {
          this.setState({ sendBtnLoading: false });
          this.toast.show({
            severity: "error",
            summary: error.response.status,
            detail:
              error.response.statusText + "." + " Contact info@rebelskool.com",
            life: 3000,
          });
        });
    }
  }

  emailSendAction(e, rowData) {
    // confirmPopup({
    //   target: e.currentTarget,
    //   message: "Confirm Send Email to " + rowData.recipient,
    //   icon: "pi pi-exclamation-triangle",
    //   accept: this.acceptToSendMail.bind(this, rowData),
    // });
  }

  sendMailBtnBodyTemplate(rowData) {
    if (this.state.currentEntryNo == rowData.entryNo) {
      return (
        <Button
          className="EmailQueue_SendMailBtn"
          onClick={(e) => this.emailSendAction(e, rowData)}
          icon={
            this.state.sendBtnLoading ? "pi pi-spin pi-spinner" : "pi pi-send"
          }
        ></Button>
      );
    } else {
      return (
        <Button
          className="EmailQueue_SendMailBtn"
          onClick={(e) => this.emailSendAction(e, rowData)}
          icon="pi pi-send"
        ></Button>
      );
    }
  }

  onRowEditComplete = (e) => {
    let { newData } = e;
    if (userSessionAuthKeyReturnValue) {
      let updateRecipient = {
        entryNo: newData.entryNo,
        recipient: newData.recipient,
      };

      axios
        .post(
          "/siteOwner/updateEmailQueue",
          updateRecipient,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          if (response.data == "Success") {
            axios
              .get(
                "/siteOwner/getEmailQueueForSiteOwner",
                userSessionAuthKeyReturnValue
              )
              .then((response) => {
                let respData = response.data;
                this.setState({
                  emailQueueData: respData,
                });
              });
          }
        });
    }
  };

  recipientEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };

  sideBarStatus = (e) => {
    this.setState({
      sideBarStatus: e,
    });
  };

  render() {
    const { userTableValues, sideBarStatus,emailQueueData } = this.state;
    const header = (
      <div className="PrivateUsers-Header-Tab">
        {TranslationFile.PrivateUsers[userSelectedLanguage]}
        {/* Private Users */}
        <span className="p-input-icon-left">
          {/* <i className="pi pi-search" /> */}
          <InputText
            type="search"
            onInput={(e) => this.setState({ globalFilter: e.target.value })}
            placeholder={TranslationFile.GlobalSearch[userSelectedLanguage]}
          />
        </span>
      </div>
    );
    return (
      <div className="PrivateUsers-Main-Div">
Hello
{this.state.showPrivateUsers && (
          <div>
            <div>
              <HeaderNavBar />
            </div>
            <div className="PrivateUsers_Sidebar">
              <Sidebar
                children={"PrivateUsers"}
                sideBarStatus={this.sideBarStatus.bind(this)}
              />
            </div>
            <div
              className={
                sideBarStatus
                  ? "PrivateUsers-DataTable"
                  : "PrivateUsers-DataTableClose"
              }
              // className="PrivateUsers-DataTable"
            >
              {console.log(userTableValues,"!!!!!!")}
              {console.log(emailQueueData,"¤¤¤¤")}
              <div className="PrivateUsers-Header-Title"> {header} </div>
              <div>
              <DataTable
                 value={this.state.userTableValues}
                 dataKey="id"
                 globalFilter={this.state.globalFilter}
                 emptyMessage="No Records found."
                 editMode="row"
                 onRowEditComplete={(e) => this.onRowEditComplete(e)}
                 paginator
                 rows={5}
                 rowsPerPageOptions={[5, 10, 25, 50]}
                
              >
                <Column
                    field="userID"
                    header={TranslationFile.UserID[userSelectedLanguage]}
                    filter
                    className="SoPrivate_user"
                  ></Column>
                  <Column
                    field="userName"
                    header={TranslationFile.UserName[userSelectedLanguage]}
                    filter
                  ></Column>
                  <Column
                    field="emailID"
                    header={TranslationFile.Email[userSelectedLanguage]}
                    filter
                  ></Column>
                  <Column
                    field="mobileNo"
                    header={TranslationFile.MobileNo[userSelectedLanguage]}
                    filter
                  ></Column>
                  <Column
                    field="isEmailVerified"
                    header={
                      TranslationFile.IsEmailVerified[userSelectedLanguage]
                    }
                    filter
                  ></Column>
                  <Column
                    field="createdAtDateTime"
                    header={
                      TranslationFile.CreatedDateTime2[userSelectedLanguage]
                    }
                    filter
                  ></Column>
              </DataTable>
              </div>
            </div>
            <Dialog
              visible={this.state.reportsDialogVisible}
              onHide={() => this.setState({ reportsDialogVisible: false })}
              header={TranslationFile.UserReportList[userSelectedLanguage]}
            ></Dialog>
          </div>
        )}
      </div>
    );
  }
}
export default PrivateUsers;
